import React from "react";
import { graphql, Link } from "gatsby";
import CustomMarkdown from "../utils/customMarkdown"

import "../scss/secondary-page.scss";
import 'uikit/dist/css/uikit.css'

import SEO from "../components/seo";
import Layout from "../components/layout";
import useSiteMetadata from "../utils/useSiteMetadata"
import { removeSlashFromSlug } from '../utils/additionalFunctions';

import Img from "gatsby-image";
import GetImgUrl from "../utils/customFunctions";
// import QuoteCarousel from "../components/quote-carousel";
// import Button from "../components/basic-components/button";
// import VideoComponent from "../components/video-component";
// import Swatches from "../components/swatches";
// import Button from "../components/basic-components/button"

import loadable from '@loadable/component'


const Button = loadable(() => import("../components/basic-components/button"))
const VideoComponent = loadable(() => import("../components/video-component"))
const Swatches = loadable(() => import("../components/swatches"))
const QuoteCarousel = loadable(() => import("../components/quote-carousel"))
const Branches = loadable(() => import("../components/branches-component"))


export const query = graphql`
  query SecondaryPagesQuery($id: Int) {
    strapiSecondaryPages(strapiId: { eq: $id }) {
      SEOTitle
      SEODescription
      Canonical
      MetaTags{
        MetaName
        MetaContent
      }
      Name
      slug
      id
      Header {
        RightBgColor
        LeftBgColor
        Title
        Subtitle
        Background {
          localFile {
            publicURL
            childImageSharp {
              fluid(quality: 90, maxWidth: 1900) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        CTA
        CTASecondary
        URL
        URLSecondary
        Visible
      }
      PageComponents
    }


    

  }
`;

const SecondaryPage = ({ data }) => {
  var headerData = {
    headerTitle: data.strapiSecondaryPages.Header.Title,
    headerDescription: data.strapiSecondaryPages.Header.Subtitle,
    headerCTAPrimaryLabel: data.strapiSecondaryPages.Header.CTA,
    headerCTAPrimaryUrl: data.strapiSecondaryPages.Header.URL,
    headerCTASecondaryLabel: data.strapiSecondaryPages.Header.CTASecondary,
    headerCTASecondaryUrl: data.strapiSecondaryPages.Header.URLSecondary,
    headerBgImage:
      data.strapiSecondaryPages.Header.Background.localFile.childImageSharp
        .fluid,
    headerRightBgColor:data.strapiSecondaryPages.Header.RightBgColor ? data.strapiSecondaryPages.Header.RightBgColor : '',
    headerLeftBgColor:data.strapiSecondaryPages.Header.LeftBgColor ? data.strapiSecondaryPages.Header.LeftBgColor : '',
  };


  

  const strapiPageComponents = data.strapiSecondaryPages.PageComponents
  const branches = strapiPageComponents.filter((item) => item.BranchesList)
  const branchList = branches ? branches[0]?.BranchesList: []
  const { siteUrl: url } = useSiteMetadata();

  return (
    <div>
      <Layout
        pageData={headerData}
        headerShow={data.strapiSecondaryPages.Header.Visible}
        
      >
        <SEO title={data.strapiSecondaryPages.SEOTitle?data.strapiSecondaryPages.SEOTitle:data.strapiSecondaryPages.Header.Title} 
         description={data.strapiSecondaryPages.SEODescription} 
         meta={data.strapiSecondaryPages.MetaTags}
         canonical={data.strapiSecondaryPages.Canonical ? data.strapiSecondaryPages.Canonical : `${url + '/news/' + removeSlashFromSlug(data.strapiSecondaryPages.slug)}`}
        />
        <div className=" padding-top lateral-padding padding-bottom">

       
        <div className="secondary-page ">
          {data.strapiSecondaryPages.PageComponents.map((item, index) => {
            return (
              <div key={index}>
                {item.Text ? (
                  <>
                    <CustomMarkdown children={item.Text} />
                   {item.Links ?
                   <Link to={`${item.Links.URL}`}>
                      <Button use="form" label={item.Links.Link}/>
                    </Link>  : ''}
                  </>
                ) : (
                  ""
                )}

                {item.Testimonial ? (<div className="">

                  <QuoteCarousel quoteList={item.Testimonial}></QuoteCarousel>
                </div>
                ) : (
                  ""
                )}

                {item.Images ? ( <div className="image_wrap">
                   <div className="image-section uk-cover-container uk-margin-none">
                    {/* <img src={item.Images.publicURL} alt="" data-uk-cover/> */}
                    <Img
                      // fluid={item.Images.childImageSharp.fluid}
                      fluid={GetImgUrl(item.Images.localFile___NODE).fluid}
                      alt={"image"}
                      data-uk-cover
                    ></Img>
                  </div>
                </div>
                 
                ) : (
                  ""
                )}

               
              </div>
            );
          })}

        
          {
  data.strapiSecondaryPages.slug === 'granite-swatches' ? <Swatches /> : ''
}
          
        </div>

        
</div>
        
        {/* {branchList ? <Branches branchList={branchList}/> : ''} */}

        {data.strapiSecondaryPages.PageComponents.map((item, index) => {
          return item.YoutubeURL ? (
            <div  className="padding-bottom " key={index}>

              

                  <VideoComponent  src={item.YoutubeURL} title={item.Title}/>
            </div>
                ) : ('')
              
              {/* console.log('item', item) */}
        })}
      </Layout>
    </div>
  );
};

export default SecondaryPage;
